import React from 'react';
import { SEO } from '@components/SEO';
import { PageBlock } from '@components/PageBlock';
import Iframe from 'react-iframe';
import { PageContainer } from '@components/PageContainer';
import { Wrapper } from '@components/Wrapper';

import styles from './style.module.scss';

const Career = () => (
  <PageContainer isSideMenuPage>
    <SEO title="Career" />
    <div>
      <PageBlock
        title="Join our team of experts "
        titleContent={
          <span>
            In {'{j:Mind.Systems}'}, we make sure that you have all you need to grow professionally, personally and
            financially. We also respect your life outside of career aspirations (which is better than cookies!). Loud
            words aside, you will not regret being a part of this team. And cookies, too.
          </span>
        }
        content={
          <div className={styles.textContainer}>
            <h3 className={styles.title}>Recent openings</h3>
            If you are an expert in something that is not on our list yet, consider sending an application. You might
            become that one-most-important person in your field.
          </div>
        }
      />
      <Wrapper>
        <div className={styles.iframeContainer}>
          <Iframe id="frame" url="https://j-mind-systems.breezy.hr/?" className={styles.iframe} />
        </div>
      </Wrapper>
    </div>
  </PageContainer>
);

export default Career;
